import React from 'react';
import './styles.scss';
import { useCookies } from 'react-cookie';

// eslint-disable-next-line no-unused-vars
import Advisor from '../../components/Advisor';
import Roadmap from '../../components/Roadmap';

// eslint-disable-next-line no-unused-vars
import Partners from '../../assets/images/drawable-xxhdpi/partners.png';
import { VIDEO_URL } from '../../assets/urls';

export default function About() {
  const [cookies] = useCookies(['lang']);
  return (
    <div className='about-wrap'>
      <div className='top'>
        <video autoPlay muted loop playsInline controls={false}>
          <source src={VIDEO_URL} />
        </video>
        <h1 className='big-title fade-in'>About</h1>
        <div className='info'>
          <div className='left'>
            <h1>[ CREATOR ECONOMY ]</h1>
          </div>
          <div className='right'>
            {cookies.lang === 'EN' ? (
              <pre>
                {`FANC Tokens (fanC) is a blockchain reward project 
designed for creators and users of the content platform CELEBe. 
In CELEBe, we establish a 'creator economy' and provide 
new services to users with NFT content that reinforces 
the user-rewarding system.
`}
              </pre>
            ) : (
              <pre>
                {`팬시토큰(fanC)은 숏폼 콘텐츠 플랫폼 'CELEBe'를 사용하는
크리에이터, 사용자를 위한 보상형 블록체인 프로젝트입니다.
'CELEBe'를 통해 '크리에이터 이코노미'를 구축하고, 
사용자 보상형 프로세스를 강화한 NFT 콘텐츠로 
사용자에게 새로운 서비스를 제공합니다.`}
              </pre>
            )}
          </div>
        </div>
      </div>
      <div className='roadmap'>
        <h1 className='big-title'>ROADMAP</h1>
        <Roadmap />
      </div>
      {/*<div className='advisor'>*/}
      {/*  <h1 className='big-title'>TEAM & ADVISOR</h1>*/}
      {/*  <Advisor />*/}
      {/*</div>*/}
      {/*<div className='partners'>*/}
      {/*  <h1 className='big-title'>PARTNERS</h1>*/}
      {/*  <img src={Partners} alt='' />*/}
      {/*</div>*/}
    </div>
  );
}
